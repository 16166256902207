.navigation {
  padding: var(--p-space-5);
  background-color: var(--p-surface-subdued);
  border-top: var(--p-border-divider);
}
.navigation.Polaris-Card__Section:first-child {
  border-top-left-radius: var(--p-border-radius-2);
  border-top-right-radius: var(--p-border-radius-2);
}
.navigation nav div.Polaris-ButtonGroup {
  margin: 0 auto;
  max-width: 80px;
}
