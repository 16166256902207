.loadingSpinner {
  height: 100%;
  margin: auto;
  position: relative;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.spinner div {
  margin-bottom: 10px;
}
